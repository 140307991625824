<template>
  <v-row class="justify-center">
    <v-col cols="4">
      <v-card>
        <v-container>
          <v-row class="flex-column">
            <p class="text-center font-weight-light grey--text legend">
              Taille: {{ blob ? Math.floor(blob.size / 1024) : 0}} Ko
            </p>
          </v-row>
          <v-row class="mb-4 justify-space-around">
            <audio controls :src="url" type="audio/mp3"></audio>
          </v-row>
          <v-row class="justify-space-around">
            <v-btn fab color="red" dark @click="startRecording()">
              <v-progress-circular
                v-if="isRecording" indeterminate color="white">
              </v-progress-circular>
              <v-icon v-else >mdi-microphone</v-icon>
            </v-btn>
            <v-btn :disabled="!isRecording" :dark="isRecording" fab color="blue"
             @click="stopRecording()">
              <v-icon :disabled="!isRecording">mdi-stop</v-icon>
            </v-btn>
            <v-btn :disabled="isRecording" :dark="!isRecording" fab color="green" @click="upload()">
              <v-icon :disabled="isRecording">mdi-upload</v-icon>
            </v-btn>
          </v-row>
          <v-row class="justify-center mt-3">
          <v-btn dark color="orange" @click="process()" grow>
            <v-icon>mdi-script</v-icon> Process
          </v-btn>
        </v-row>
          <v-sparkline fill :gradient="['#42b3f4']" smooth="25" :value="histogram">
          </v-sparkline>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="8">
      <v-card>
        <v-container>
          <v-row class="justify-space-around">
          </v-row>
          <v-sparkline
          :labels="labels"
          label-size="5"
          top
          show-labels
          :value="value"
          :smooth="16"
          :gradient="['#42b3f4', '#33CC00']"
          type="bar"
          auto-line-width
          ></v-sparkline>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Recorder from '@/recorder/recorder';

export default {
  name: 'Recorder',
  data: () => ({
    logMessages: '',
    audioContext: null,
    recorder: null,
    isRecording: false,
    blob: null,
    url: 'undefined',
    histogram: [0],
    labels: [],
    value: [],
  }),

  methods: {
    updateHistogram(histogram) {
      this.histogram = [...histogram.data];
    },

    async init(callback) {
      this.audioContext = new AudioContext({ sampleRate: 16000 });
      this.recorder = new Recorder(this.audioContext, {
        numChannels: 1,
        onAnalysed: (data) => callback(data),
      });

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.recorder.init(stream);
      this.isInitalized = true;
    },

    async startRecording() {
      if (!this.isInitalized) {
        await this.init(this.updateHistogram);
      }

      await this.recorder.start();
      this.isRecording = true;
    },

    async stopRecording() {
      const data = await this.recorder.stop();
      this.url = URL.createObjectURL(data.blob);
      this.isRecording = false;
      this.isInitalized = false;
      this.blob = data.blob;
      delete this.audioContext;
    },

    download() {
      Recorder.download(this.blob, 'my-audio-file'); // downloads a .wav file
    },

    async upload() {
      /*
      if (!this.blob) {
        return;
      }

      const formData = new FormData();
      formData.append('audioFile', this.blob);

      const headers = {
        Accept: 'application/json',
        'Client-Application-Version': '1.0',
        'Client-Application-Name': 'slodim',
      };

      const response = await fetch('/upload', { method: 'POST', headers, body: formData });
      */
    },

    async process() {
      const a = 1;
      return a;
      /*
      const response = await fetch('/script');
      if (!response.ok) {
        // TODO : a supprimer, fix pour le deploiment
        a = 1;
        a = a + 1;
      } else {
        const data = await response.json();

        // Retrieve start times for serve and it into 'value'
        data.result.forEach((item) => {
          this.value.push(item.start);
        });

        // Transform 'text' response into labels array
        [...this.labels] = data.text.split(' ');

        // Gather retrieved values in 1 array
        const xValues = [];
        let i = 0;
        for (i = 0; i < data.result.length; i += 1) {
          xValues.push({
            start: data.result[i].start,
            label: this.labels[i],
            value: data.result[i].conf,
          });
        }

        // Min and max in 'value' array
        const maximum = this.value[this.value.length - 1];
        // eslint-disable-next-line
        const [minimum, _] = this.value;

        // Insert empty values
        for (i = minimum - 1; i < maximum + 1; i += 0.5) {
          xValues.push({ start: i, label: ' ', value: 0.0 });
        }

        // Sort the array to add spaces between actual data in charts
        xValues.sort((a, b) => (a.start - b.start));
        [...this.labels] = [];
        [...this.value] = [];

        // Update displayed data
        xValues.forEach((item) => {
          this.labels.push(item.label);
          this.value.push(item.value);
        });
      }
      */
    },
  },
};
</script>
